import {
  CLIENT_GET_LIST,
  CLIENT_GET_LIST_SUCCESS,
  CLIENT_GET_LIST_ERROR,
  CLIENT_GET_LIST_WITH_FILTER,
  CLIENT_GET_LIST_WITH_ORDER,
  CLIENT_GET_LIST_SEARCH,
  CLIENT_ADD_ITEM,
  CLIENT_ADD_ITEM_SUCCESS,
  CLIENT_ADD_ITEM_ERROR,
  CLIENT_EDIT_ITEM,
  CLIENT_EDIT_ITEM_SUCCESS,
  CLIENT_EDIT_ITEM_ERROR,
  CLIENT_SELECTED_ITEMS_CHANGE,
  CLIENT_SEND_INVITATION,
  CLIENT_ACCEPT_INVITATION,
  CLIENT_ACCEPT_INVITATION_EMAIL,
} from '../actions';

export const getClientList = (user) => ({
  type: CLIENT_GET_LIST,
  payload: user,
});

export const getClientListSuccess = (items) => ({
  type: CLIENT_GET_LIST_SUCCESS,
  payload: items,
});

export const getClientListError = (error) => ({
  type: CLIENT_GET_LIST_ERROR,
  payload: error,
});

export const getClientListWithFilter = (column, value) => ({
  type: CLIENT_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getClientListWithOrder = (column) => ({
  type: CLIENT_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getClientListSearch = (keyword) => ({
  type: CLIENT_GET_LIST_SEARCH,
  payload: keyword,
});

export const addClientItem = (item) => ({
  type: CLIENT_ADD_ITEM,
  payload: item,
});

export const addClientItemSuccess = (items) => ({
  type: CLIENT_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addClientItemError = (error) => ({
  type: CLIENT_ADD_ITEM_ERROR,
  payload: error,
});
export const editClientItem = (item) => ({
  type: CLIENT_EDIT_ITEM,
  payload: item,
});

export const editClientItemSuccess = (items) => ({
  type: CLIENT_EDIT_ITEM_SUCCESS,
  payload: items,
});

export const editClientItemError = (error) => ({
  type: CLIENT_EDIT_ITEM_ERROR,
  payload: error,
});
export const selectedClientItemsChange = (selectedItems) => ({
  type: CLIENT_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

export const sendInvitation = (client) => ({
  type: CLIENT_SEND_INVITATION,
  payload: client,
});

export const acceptInvitation = ({ payload }) => ({
  type: CLIENT_ACCEPT_INVITATION,
  payload,
});

export const sendInvitationToEmail = (payload) => ({
  type: CLIENT_ACCEPT_INVITATION_EMAIL,
  payload,
});
