/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */

export const EDIT_USER = 'EDIT_USER';
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const GET_USER_INFO = 'GET_USER_INFO';
/* CLIENTAPP*/
export const CLIENT_GET_LIST = 'CLIENT_GET_LIST';
export const CLIENT_GET_LIST_SUCCESS = 'CLIENT_GET_LIST_SUCCESS';
export const CLIENT_GET_LIST_ERROR = 'CLIENT_GET_LIST_ERROR';
export const CLIENT_GET_LIST_WITH_FILTER = 'CLIENT_GET_LIST_WITH_FILTER';
export const CLIENT_GET_LIST_WITH_ORDER = 'CLIENT_GET_LIST_WITH_ORDER';
export const CLIENT_GET_LIST_SEARCH = 'CLIENT_GET_LIST_SEARCH';
export const CLIENT_ADD_ITEM = 'CLIENT_ADD_ITEM';
export const CLIENT_ADD_ITEM_SUCCESS = 'CLIENT_ADD_ITEM_SUCCESS';
export const CLIENT_ADD_ITEM_ERROR = 'CLIENT_ADD_ITEM_ERROR';
export const CLIENT_EDIT_ITEM = 'CLIENT_EDIT_ITEM';
export const CLIENT_EDIT_ITEM_SUCCESS = 'CLIENT_EDIT_ITEM_SUCCESS';
export const CLIENT_EDIT_ITEM_ERROR = 'CLIENT_EDIT_ITEM_ERROR';
export const CLIENT_SELECTED_ITEMS_CHANGE = 'CLIENT_SELECTED_ITEMS_CHANGE';
export const CLIENT_SEND_INVITATION = 'CLIENT_SEND_INVITATION';
export const CLIENT_ACCEPT_INVITATION = 'CLIENT_ACCEPT_INVITATION';
export const CLIENT_ACCEPT_INVITATION_EMAIL = 'CLIENT_ACCEPT_INVITATION_EMAIL';
/* IDEAAPP*/
export const CLIENT_SET_CURRENTID = 'CLIENT_SET_CURRENTID';
export const IDEA_GET_LIST = 'IDEA_GET_LIST';
export const IDEA_GET_LIST_SUCCESS = 'IDEA_GET_LIST_SUCCESS';
export const IDEA_GET_LIST_ERROR = 'IDEA_GET_LIST_ERROR';
export const IDEA_GET_LIST_WITH_FILTER = 'IDEA_GET_LIST_WITH_FILTER';
export const IDEA_GET_LIST_WITH_ORDER = 'IDEA_GET_LIST_WITH_ORDER';
export const IDEA_GET_LIST_SEARCH = 'IDEA_GET_LIST_SEARCH';
export const IDEA_ADD_ITEM = 'IDEA_ADD_ITEM';
export const IDEA_ADD_ITEM_SUCCESS = 'IDEA_ADD_ITEM_SUCCESS';
export const IDEA_ADD_ITEM_ERROR = 'IDEA_ADD_ITEM_ERROR';
export const IDEA_EDIT_ITEM = 'IDEA_EDIT_ITEM';
export const IDEA_EDIT_ITEM_SUCCESS = 'IDEA_EDIT_ITEM_SUCCESS';
export const IDEA_EDIT_ITEM_ERROR = 'IDEA_EDIT_ITEM_ERROR';
export const IDEA_SELECTED_ITEMS_CHANGE = 'IDEA_SELECTED_ITEMS_CHANGE';
export const IDEA_DELETE = 'IDEA_DELETE';
export const IDEA_DELETE_SUCCESS = 'IDEA_DELETE_SUCCESS';
export const IDEA_DELETE_ERROR = 'IDEA_DELETE_ERROR';
export const IDEA_COMMENT = 'IDEA_COMMENT';
export const IDEA_COMMENT_SUCCESS = 'IDEA_COMMENT_SUCCESS';
export const IDEA_COMMENT_ERROR = 'IDEA_COMMENT_ERROR';
/*VOTE*/
export const VOTE_UP = 'VOTE_UP';
export const VOTE_UP_SUCCESS = 'VOTE_UP_SUCCESS';
export const VOTE_UP_ERROR = 'VOTE_UP_ERROR';
export const VOTE_DOWN = 'VOTE_DOWN';
export const VOTE_DOWN_SUCCESS = 'VOTE_DOWN_SUCCESS';
export const VOTE_DOWN_ERROR = 'VOTE_DOWN_ERROR';
/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

export * from './menu/actions';
export * from './settings/actions';
export * from './auth/actions';
export * from './trainer/actions';
export * from './idea/actions';
