import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getDateWithFormat } from '../../helpers/Utils';
import { auth, store } from '../../helpers/Firebase';
import axios from 'axios';

import {
  CLIENT_GET_LIST,
  CLIENT_ADD_ITEM,
  CLIENT_EDIT_ITEM,
  CLIENT_SEND_INVITATION,
  CLIENT_ACCEPT_INVITATION,
  CLIENT_ACCEPT_INVITATION_EMAIL,
} from '../actions';

import {
  getClientListSuccess,
  getClientListError,
  addClientItemSuccess,
  addClientItemError,
  editClientItemSuccess,
  editClientItemError,
} from './actions';

import { roles, apiUrl } from '../../constants/defaultValues';

const getClientListRequest = async () => {
  try {
    const currentUser = localStorage.getItem('auth_id');
    const allClients = await store
      .collection('users')
      .get()
      .then((querySnapshot) => {
        const result = querySnapshot.docs
          .filter((client) => client.data().role === roles.client)
          .map((client) => {
            return { id: client.id, ...client.data() };
          });
        if (result.length === 0) {
          return [];
        } else {
          return result;
        }
      });
    const clients = allClients.filter((client) => {
      return client.trainers && client.trainers.includes(currentUser);
    });
    return { clients, allClients };
  } catch (e) {
    console.log('getClientListRequest: ', e);
  }
};

function* getClientListItems(payload) {
  try {
    const response = yield call(getClientListRequest, payload);
    console.log('response', response);
    yield put(getClientListSuccess(response));
  } catch (error) {
    yield put(getClientListError(error));
  }
}

const addClientItemRequest = async (item) => {
  item.createDate = getDateWithFormat();
  item.updateDate = getDateWithFormat();
  // items.splice(0, 0, item);
  await store.collection('clients').add(item);

  return item;
};

function* addClientItem({ payload }) {
  try {
    const response = yield call(addClientItemRequest, payload);
    yield put(addClientItemSuccess(response));
  } catch (error) {
    yield put(addClientItemError(error));
  }
}
const getClientItemRequest = async (item) => {
  item.updateDate = getDateWithFormat();
  let clientCollection = store.collection('clients').doc(item.id);
  var data = Object.assign({}, item);
  delete data.id;

  clientCollection.get().then(function (thisdoc) {
    if (thisdoc.exists) {
      clientCollection.update(data);
    }
  });
  return item;
};

function* getClientItem({ payload }) {
  try {
    const response = yield call(getClientItemRequest, payload);
    yield put(editClientItemSuccess(response));
  } catch (error) {
    yield put(editClientItemError(error));
  }
}

const sendInvitationRequest = async ({ payload }) => {
  console.log('client', payload);
  const trainerID = localStorage.getItem('auth_id');
  const token = localStorage.getItem('access_token');
  const { firstName, lastName } = await (
    await store.collection('users').doc(trainerID).get()
  ).data();
  const trainerName = `${firstName} ${lastName}`;
  const config = {
    headers: { 'x-access-token': token },
  };
  const formData = { trainerName, trainerID, ...payload };
  // const clientID =
  const res = await axios.post(apiUrl + 'trainer/sendInvite', formData, config);
};

function* sendInvitation(payload) {
  console.log('sendInvitation', payload);

  try {
    const response = yield call(sendInvitationRequest, payload);
    // yield put(se)
  } catch (error) {
    console.log('invitation error', error);
  }
}

function* sendInvitationToEmail(payload) {
  try {
    const response = yield call(sendInvitationRequest, payload);
    // yield put(se)
  } catch (error) {
    console.log('invitation error', error);
  }
}

const acceptInvitationAsync = async (formData) => {
  const token = localStorage.getItem('access_token');
  const config = {
    headers: { 'x-access-token': token },
  };
  const res = await axios.post(
    apiUrl + 'trainer/acceptInvite',
    formData,
    config
  );
  return res;
};

function* acceptInvitation(payload) {
  console.log('payload', payload);
  const { history, result } = payload.payload;
  try {
    const res = yield call(acceptInvitationAsync, result);
    console.log('response', res);
  } catch (error) {
    console.log('error', error);
  }
  history.push('/client');
}

export function* watchGetList() {
  yield takeEvery(CLIENT_GET_LIST, getClientListItems);
}

export function* watchAddItem() {
  yield takeEvery(CLIENT_ADD_ITEM, addClientItem);
}

export function* watchEditItem() {
  yield takeEvery(CLIENT_EDIT_ITEM, getClientItem);
}

export function* watchSendInvitation() {
  yield takeEvery(CLIENT_SEND_INVITATION, sendInvitation);
}

export function* watchSendInvitationToEmail() {
  yield takeEvery(CLIENT_ACCEPT_INVITATION_EMAIL, sendInvitationToEmail);
}

export function* watchAcceptInvitation() {
  yield takeEvery(CLIENT_ACCEPT_INVITATION, acceptInvitation);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchAddItem),
    fork(watchEditItem),
    fork(watchSendInvitation),
    fork(watchSendInvitationToEmail),
    fork(watchAcceptInvitation),
  ]);
}
